import * as React from "react"
import Button from "./button"
import Emphasis from "./emphasis"
import H2 from "./h2"

// TODO : Add form handling
// https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/?_gl=1*skxb0q*_gcl_aw*R0NMLjE2NDIzNTg3MjguQ2p3S0NBancxSmVKQmhCOUVpd0FWNjEyeXdGSVMwRk1RRzdvMS1qbGlNSmdobzQ2ZEJpaTl5Wnlidl9DaUZERVNlS0xyUWU2Slp2T3lSb0NBcm9RQXZEX0J3RQ..&_ga=2.182876134.1518386865.1642357685-205660720.1629940984&_gac=1.79774309.1642358728.CjwKCAjw1JeJBhB9EiwAV612ywFIS0FMQG7o1-jliMJgho46dBii9yZybv_CiFDESeKLrQe6JZvOyRoCAroQAvD_BwE
const ContactForm = ({ id, className }) => {
  const contactFormRef = React.createRef()

  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [description, setDescription] = React.useState("")

  const handleSubmit = event => {
    event.preventDefault()
    console.log(event)

    console.table([
      { field: "Name", value: name },
      { field: "Email", value: email },
      { field: "Phone", value: phone },
      { field: "Description", value: description },
    ])

    contactFormRef.current.submit()
  }

  const submissionEmailSubject = `New Canopy quote request from ${name}`

  // Consider adding https://formik.org/ for form validation stuff

  return (
    <section id={id} className={`p-8 ${className}`}>
      <H2 className="text-center">Let's get started</H2>

      <form
        ref={contactFormRef}
        name="contact"
        action={`/thank-you?name=${name}&email=${email}${
          phone ? "&phone=" + phone : ""
        }`}
        method="post"
        onSubmit={handleSubmit}
        data-netlify="true"
        netlify-honeypot="honey-pot"
      >
        <div className="w-full lg:!w-1/2 mx-auto py-4">
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="subject" value={submissionEmailSubject} />

          {/* https://docs.netlify.com/forms/spam-filters/#honeypot-field */}
          <input type="hidden" name="honey-pot" />

          <div className="py-4">
            <label htmlFor="name">
              <Emphasis>Name:</Emphasis>
            </label>
            <input
              id="name"
              name="name"
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              className="form-input mt-1 text-lg w-full px-4 py-3 rounded-lg border-gray-100 focus:border-primary-highlighted focus:ring-1 focus:ring-primary-highlighted
shadow-md"
            />
          </div>
          <div className="py-4">
            <label htmlFor="email">
              <Emphasis>Email:</Emphasis>
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              className="form-input mt-1 text-lg w-full px-4 py-3 rounded-lg border-gray-100 focus:border-primary-highlighted focus:ring-1 focus:ring-primary-highlighted
shadow-md"
            />
          </div>
          <div className="py-4">
            <label htmlFor="phone">
              <Emphasis>Phone (optional):</Emphasis>
            </label>
            <input
              id="phone"
              name="phone"
              type="tel"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              className="form-input mt-1 text-lg w-full px-4 py-3 rounded-lg border-gray-100 focus:border-primary-highlighted focus:ring-1 focus:ring-primary-highlighted
shadow-md"
            />
          </div>
          <div className="py-4">
            <label htmlFor="description">
              <Emphasis>How can we help?</Emphasis>
            </label>
            <textarea
              id="description"
              name="description"
              rows="8"
              value={description}
              onChange={e => setDescription(e.target.value)}
              className="form-textarea mt-1 text-lg w-full px-4 py-3 rounded-lg border-gray-100 focus:border-primary-highlighted focus:ring-1 focus:ring-primary-highlighted
shadow-md"
            ></textarea>
          </div>
        </div>

        <div className="text-center">
          <div className="py-2">
            <Button
              type="submit"
              className="w-full text-4xl !p-6 lg:!px-4 lg:!py-2 lg:!w-1/5 lg:!text-2xl"
            >
              Submit
            </Button>
          </div>
          <div className="p-2">
            <Emphasis>or call now</Emphasis>
          </div>
          <div className="pt-6 pb-12">
            <a
              href="tel:8142290180"
              className="text-4xl font-extrabold underline text-primary hover:text-primary-highlighted active:text-primary-darkened hover:text-5xl duration-200"
            >
              814.229.0180
            </a>
          </div>
        </div>
      </form>
    </section>
  )
}

export default ContactForm
