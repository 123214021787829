import * as React from "react"
import ContactForm from "../components/contact-form"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function ContactPage() {
  return (
    <Layout>
      <Seo title="Contact" />
      <ContactForm />
    </Layout>
  )
}
